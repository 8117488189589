import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import Table from '@components/aria/UI/Table/Table';
import NewModal from '@components/Modal/NewModal';

const Clients = () => {
  const uploadRef = useRef(null);
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const [tableMetaData, setTableMetaData] = useState(1);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastActionClientId, setLastActionClientId] = useState();
  const [modal, setModal] = useState({ type: null, data: null, message: null });

  const COLUMNS = [
    { name: t('Id'), key: 'id', width: 'auto' },
    { name: t('Name'), key: 'name', width: 'auto' },
    { name: t('Email'), key: 'email', width: 'auto' },
    { name: t('Phone'), key: 'phone', width: 'auto' },
    { name: t('Address'), key: 'addr1', width: 'auto' },
    { name: t('MC Number'), key: 'mcnum', width: 'auto' },
    { name: t('DOT Number'), key: 'dotnum', width: 'auto' },
    {
      name: t('Signed NOA'),
      key: 'noa',
      width: 'auto',
      method: (noa) => {
        if (!noa.thumb) return <div />;
        return <img src={noa.thumb} alt="noa document" />;
      },
    },
  ];

  const onUploadNOAClick = (id) => {
    uploadRef.current.click();
    setLastActionClientId(id);
  };

  const openNOARemoveModal = (id) => {
    setModal({
      type: 'deleteNOA',
      data: id,
      message: `Are you sure you want to delete NOA from ${
        clients.find((client) => client.id === id)?.name
      }?`,
    });
  };

  const modalActions = {
    close: () => setModal({ type: null, data: null, message: null }),
    deleteNOA: () => onDeleteNOAConfirm(),
  };

  const getUsers = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('clients', { params: filters })
      .then((res) => {
        setLoading(false);
        setClients(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getUsers', { error: err });
        toast.error(t('Error'));
      });
  };

  const onDeleteNOAConfirm = () => {
    const deleteToast = toast.loading('Deleting Signed NOA', {
      duration: Infinity,
    });
    modalActions.close();
    api
      .jsonAPI()
      .delete(`clients/${modal.data}/noa`)
      .then((res) => {
        const { data: newClient } = res;
        const newClients = clients.map((client) => {
          if (client.id === newClient?.id) {
            return newClient;
          }
          return client;
        });
        setClients(newClients);
        toast.success('Signed NOA successfully deleted');
      })
      .catch(() => {
        toast.error('Error while deleting Signed NOA');
      })
      .finally(() => {
        toast.dismiss(deleteToast);
      });
  };

  const userActions = [
    {
      label: 'Upload Signed NOA',
      icon: 'upload',
      onClick: onUploadNOAClick,
    },
    {
      label: 'Delete Signed NOA',
      icon: 'upload',
      onClick: openNOARemoveModal,
      condition: (client) => client.noa?.url,
    },
  ];

  const onUploadNoa = (e) => {
    const importToast = toast.loading('Uploading Signed NOA', {
      duration: Infinity,
    });

    const formData = new FormData();
    formData.append('noa', e.target.files[0]);

    api
      .jsonAPI()
      .patch(`clients/${lastActionClientId}`, formData)
      .then((res) => {
        const { data: newClient } = res;
        const newClients = clients.map((client) => {
          if (client.id === newClient?.id) {
            return newClient;
          }
          return client;
        });
        setClients(newClients);
        toast.success('Signed NOA successfully uploaded');
      })
      .catch(() => {
        toast.error('Error while uploading Signed NOA');
      })
      .finally(() => {
        uploadRef.current.value = '';
        toast.dismiss(importToast);
      });
  };

  useEffect(() => {
    getUsers(filters);
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getUsers(filters),
    },
  ];

  return (
    <div style={{ padding: '0 15px' }}>
      <NewModal
        title="Confirm"
        visible={modal.type}
        onSubmit={() => modalActions[modal.type]()}
        onCancel={modalActions.close}
        onCrossClick={modalActions.close}
      >
        {modal.message}
      </NewModal>
      <input
        ref={uploadRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onUploadNoa}
      />
      <Table
        columns={COLUMNS}
        items={clients}
        totalItems={tableMetaData?.count ?? 0}
        buttons={tableButtons}
        actions={userActions}
        loading={loading}
        noItemsKey="No users"
        filters={filters}
        pages={tableMetaData?.last ?? 1}
        updateFilters={updateFilters}
        sessionSettings={{ name: 'CLIENTS' }}
      />
    </div>
  );
};

export { Clients };
